.homeMobile {
  position: relative;
  min-height: 80vh;

  .skip {
    position: absolute;
    top: -65px;
    left: 16px;
    border-radius: 24px;
    background: rgba(34, 34, 34, 0.6);
    z-index: 9999;
    border: 1px solid rgba(255, 255, 255, 0.44);
  }

  .content {

    // margin-bottom: 40px;

    .productBox {
      margin: 0 12px;
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      /* 每行 2 列 */
      gap: 11px;
      /* 设置间距 */
    }

    .seeMore {
      display: flex;
      justify-content: space-between;
      align-items: center;
      color: #fff;
      font-size: 12px;
      margin: 22px auto;
      padding: 7px 20px;
      border-radius: 20px;
      background: var(--Color2, #454D6C);
      width: 110px;

      .moreIcon {
        font-size: 15px;
      }
    }
  }

  .floatButton {}

  .ant-float-btn-body .ant-float-btn-default {
    background-color: transparent !important;
  }

  .mailIcon {
    width: 26px;
    height: 26px;
    color: #fff;
  }

  .mailBox {
    position: fixed;
    bottom: 100px;
    right: 26px;
    width: 44px;
    height: 44px;
    border-radius: 30px;
    background: #F0774A;
    border: 2px solid rgba(237, 240, 254, 0.83);
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .docuBox {
    position: fixed;
    bottom: 152px;
    right: 26px;
    width: 44px;
    height: 44px;
    border-radius: 30px;
    background: #4c6ef3;
    border: 2px solid rgba(237, 240, 254, 0.83);
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .docuIcon {
    width: 31px;
    height: 31px;
    color: #fff;
  }

}

.textButton {
  background: var(--gradient-color);
}

.homeIcon {
  color: var(--primary);
}

.tourCustomList {
  list-style-type: none;
  /* 隐藏默认小圆点 */
  padding-left: 1em;
  /* 控制整体缩进 */
  margin-bottom: 0;
}

.tourCustomList li {
  position: relative;
  padding-left: 8px;
  /* 文本与小圆点的间距 */
  text-indent: -1px;
  /* 反向缩进实现对齐 */
}

.tourCustomList li::before {
  content: "•";
  /* 自定义小圆点 */
  position: absolute;
  left: -5px;
  font-size: 12px;
}
