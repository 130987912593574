.overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  background: rgba($color: #000000, $alpha: 0.3);
  z-index: 99999;


  @keyframes slideUp {
    from {
      transform: translateY(100%);
      opacity: 0;
    }

    to {
      transform: translateY(0);
      opacity: 1;
    }
  }

  @keyframes slideDown {
    from {
      transform: translateY(0);
      opacity: 1;
    }

    to {
      transform: translateY(100%);
      opacity: 0;
    }
  }

  .popup-enter {
    animation: slideUp 0.3s ease-out forwards;
  }

  .popup-exit {
    animation: slideDown 0.3s ease-in forwards;
  }

  .popup {
    background: var(--text-button);
    width: 100%;
    border-radius: 6px 6px 0 0;
    position: fixed;
    bottom: 0;
    max-height: 454px;
    overflow: scroll;

    .header {
      padding: 16px 16px 0 24px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      // border-bottom: 1px solid var(--background2);

      .headerLeft {
        color: var(--text-title);
        font-size: 14px;
        font-weight: 700;
        line-height: 142%;
      }

      .headerRight {
        .headerRightIcon {
          font-size: 20px;
          color: var(--text-disable);
        }
      }
    }

    .feedbackContent {
      padding: 0 16px 120px 24px;

      .scoreTitle {
        color: var(--text-title);
        font-size: 14px;
        font-weight: 700;
        line-height: 142%;
        padding-top: 24px;

        .titleIcon {
          color: var(--text-status2);
          font-size: 14px;
          font-weight: 700;
          line-height: 142%;
        }
      }

      .scoreWarp {
        display: flex;
        width: 312px;
        justify-content: space-between;
        margin: 12px 0 12px 4px;
      }

      .scoreBox {
        width: 24px;
        height: 20px;
        display: flex;
        justify-content: center;
        align-items: center;
        color: var(--text-title);
        font-size: 14px;
        font-weight: 400;
        background: var(--background2);
        border-radius: 4px;
      }

      .scoreBoxActive {
        background: var(--primary);
        color: var(--text-button);
      }

      .scoreTip {
        width: 312px;
        display: flex;
        justify-content: space-between;
        color: var(--text-disable);
        font-size: 12px;
        line-height: 142%;
        font-weight: 400;
        margin-left: 4px;

      }

      .question {
        display: flex;
        align-items: center;
        margin-top: 12px;
        width: max-content;

        .check_icon {
          width: 20px;
          height: 20px;
          border: 1px solid #d3d3d3;
          border-radius: 4px;
          position: relative;
        }

        .checked_icon {
          background-color: #f0774a;
          border-color: #f0774a;
        }

        .checked_icon::after {
          opacity: 1;
          transform: rotate(45deg) scale(1) translate(-50%, -50%);
          transition: all 0.2s cubic-bezier(0.12, 0.4, 0.29, 1.46) 0.1s;
          box-sizing: border-box;
          position: absolute;
          top: 50%;
          inset-inline-start: 25%;
          display: table;
          width: 6px;
          height: 10px;
          border: 2px solid #fff;
          border-top: 0;
          border-inline-start: 0;
          content: "";
        }

        .questionText {
          font-size: 14px;
          color: var(--text-title);
          margin-left: 12px;
        }
      }

      .scoreTextArea {
        background: #EDEDED;
        font-size: 16px;
      }
    }


  }

  .footer {
    position: fixed;
    bottom: 0;
    padding: 12px 0 32px 0;
    background: var(--text-button);
    width: 100%;

    .tip {
      margin-left: 24px;
      font-size: 12px;
      line-height: 14px;
      color: var(--text-status2);
    }

    .submit {
      margin: 0 auto;
      margin-top: 12px;
      border-radius: 6px;
      background: var(--linear, linear-gradient(270deg, #F76C4D 0%, #FE912C 100%));
      width: 160px;
      display: flex;
      justify-content: center;
      font-size: 12px;
      color: var(--text-button);
      padding: 7px 0;
    }
  }
}
